import React from "react";
import * as styles from "../styles/index.module.css";
import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <div className="dark:bg-black">
      <Helmet>
        <meta charSet="utf-8" />
        <title>no-comment</title>
      </Helmet>
      <main className={`h-screen flex justify-center items-center`}>
        <h1 className="sm:text-6xl text-center dark:text-white">{`/* UNDER CONSTRUCTION */`}</h1>
      </main>
    </div>
  );
}
